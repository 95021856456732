import React, { useState } from "react";

import Banner from "../../Components/Banner/Banner";

import { useSelector } from "react-redux";
import ContentWrapper from "../../Components/ContentWrapper/ContentWrapper";
import "react-responsive-iframe-viewer/dist/style.css";

import Iframe from "react-iframe";
import { dasboardLink } from "../../constants/constants";

const AnalyticsScreen = () => {
  const currentSpace = useSelector((state) => state.defaultSpace.data);
  return (
    <ContentWrapper>
      <Banner>Analytics</Banner>
      <div className={`dropdown-header d-flex align-items-center py-3 mt-5`}>
        <IframeWithLoader src={`${dasboardLink}?space_id=${currentSpace}`} />
      </div>
    </ContentWrapper>
  );
};

const IframeWithLoader = ({ src }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = () => {
    setIsLoading(false); // Stop showing loader when iframe loads
  };

  return (
    <div style={{ position: "relative", height: "1000px", width: "100%" }}>
      {/* Loader */}
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1,
          }}
        >
          <div>Loading document...</div>
        </div>
      )}

      {/* Iframe */}
      <Iframe
        url={src}
        width="100%"
        height="100%"
        frameBorder="0"
        onLoad={handleIframeLoad} // Fires when iframe content is fully loaded
      />
    </div>
  );
};

export default AnalyticsScreen;
