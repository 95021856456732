import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Banner from "../../Components/Banner/Banner";
import ReactTable from "../../Components/ReactTable/ReactTable";
import { CubeSpinner } from "../../Components/Spinners/Spinners";
import { ErrorAlert } from "../../Components/Alerts/Alerts";
import styles from "./actions.module.css";
import AccessWrapper from "../../Components/AccessWrapper/AccessWrapper";
import { MdInfo } from "react-icons/md";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import {
  filterByVal,
  getCookieByDomain,
  getStandardErrMsg,
  isPlatformAdminUser,
  userAccessFunc,
} from "../../utils/utils";

import ContentWrapper from "../../Components/ContentWrapper/ContentWrapper";
import useConfirmDelete from "../../utils/useConfirmDelete";
import FillterWrapper from "../../Components/TableFilterWrapper/TableFillterWrapper";
import {
  useAddActionMutation,
  useDeleteActionMutation,
  useGetActionsQuery,
} from "../../services/actionsService";
import { useSelector } from "react-redux";
import useFilteredData from "../../utils/useFilteredData";
import CopyRowModal from "../../Components/CopyRowModal/CopyRowModal";
import PostPerPage from "../../Components/PostPerPage/PostPerPage";
import SearchFilter from "../../Components/SearchFilter/SearchFilter";
import { AddNewBtn } from "../../Components/Buttons/Buttons";
import { ActionIcon } from "../../Icons/Icons";
import {
  useDeleteApiKeyMutation,
  useGetAPIKeysQuery,
} from "../../services/apiKeysService";
import AddNewKey from "../ApiKeys/AddNewKey";
import { getCurrentRole } from "../../utils/usePermission";

const ActionsScreen = () => {
  const navigate = useNavigate();
  const currentSpace = useSelector((state) => state.defaultSpace.data);
  const [addAction, addActionInfo] = useAddActionMutation();

  const [postsPerPage, setPostPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [activeTab, setActiveTab] = useState("action");

  // for env Variables
  const [showAddNewKey, setShowAddNewKey] = useState(false);
  const [editKey, setEditKey] = useState(null);
  const {
    data: keyData,
    error: keyError,
    isFetching: keyFetching,
  } = useGetAPIKeysQuery(currentSpace);
  const [deleteKey] = useDeleteApiKeyMutation();
  const confirmKeyDelete = useConfirmDelete(deleteKey);
  const isPlatformAdmin = isPlatformAdminUser(currentSpace);
  const currentRole = getCurrentRole(currentSpace);
  const isAllowed = currentRole["space_admin"] || isPlatformAdmin;

  const handlekeyDelete = useCallback(
    (id) => {
      confirmKeyDelete(id);
    },
    [confirmKeyDelete]
  );

  const handleEdit = useCallback(
    (environmental_variable_id, variable_name, value) => {
      setEditKey({ environmental_variable_id, variable_name, value });
      setShowAddNewKey(true);
    },
    []
  );

  const filteredKeyData = useFilteredData(keyData, searchText, filterByVal);

  const keyColumns = React.useMemo(() => {
    const baseColumns = [
      {
        Header: "ID",
        accessor: "environmental_variable_id",
      },
      {
        Header: "Variable Name",
        accessor: "variable_name",

        Cell: ({ cell }) => (
          <>
            {cell.row.original.variable_name}
            <Link
              to="#"
              className="text-body"
              onClick={() =>
                navigator.clipboard.writeText(cell.row.original.variable_name)
              }
            >
              <i className="ti ti-copy ti-sm mx-1"></i>
            </Link>
          </>
        ),
      },
      {
        Header: "Variable Value",
        accessor: "value",
        Cell: ({ cell }) => cell.row.original.value,
      },
    ];

    // Conditionally include the "Actions" column based on role
    baseColumns.push({
      Header: "Actions",
      Cell: ({ cell }) => (
        <>
          <span
            title={isAllowed ? "" : 'You don"t have edit permission'}
            className={isAllowed ? "" : "permission-disabled"}
          >
            <Link
              to="#"
              className="text-body"
              onClick={() =>
                handleEdit(
                  cell.row.original.environmental_variable_id,
                  cell.row.original.variable_name,
                  cell.row.original.value
                )
              }
            >
              <i className="ti ti-edit ti-sm mx-1" />
            </Link>
          </span>
          <span
            title={isAllowed ? "" : 'You don"t have delete permission'}
            className={isAllowed ? "" : "permission-disabled"}
          >
            <Link
              to="#"
              className="text-body delete-record"
              onClick={() =>
                handlekeyDelete(cell.row.original.environmental_variable_id)
              }
            >
              <i className="ti ti-trash ti-sm mx-1" />
            </Link>
          </span>
        </>
      ),
    });

    return baseColumns;
  }, [handleEdit, handlekeyDelete]);

  // const [filteredPosts, setFilteredPosts] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const { data, error, isFetching } = useGetActionsQuery(currentSpace);
  const [deleteDatabase] = useDeleteActionMutation();
  const confirmDelete = useConfirmDelete(deleteDatabase);

  const handleCopyClick = (row) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  const handleCopy = async (newName) => {
    const copiedData = { ...selectedRow };

    const body = {
      space_id: currentSpace,
      action_id: null,
      action_version_id: null,
      title: newName,
      description: copiedData.description,
      file_name: copiedData.file_name,
      share: copiedData.share,
      parent_id: copiedData.parent_id,
      platform_default: copiedData.platform_default,
      version: "1.0",
      stage: copiedData.actions_version[0].stage,
      config: {
        content: JSON.parse(copiedData.actions_version[0].config).content,
      },
      content: copiedData.content,
    };

    await addAction(body);
  };

  const handleDelete = useCallback(
    (id) => {
      confirmDelete(id);
    },
    [confirmDelete]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "action_id",
      },
      {
        Header: "Title",
        accessor: "title",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {row.original.space_id === -1 ? (
              <>
                <span style={{ color: "#6158ca" }}>{row.original.title}</span>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top">Platform Resource</Tooltip>
                  }
                >
                  <span style={{ marginLeft: "10px", cursor: "pointer" }}>
                    <MdInfo style={{ color: "#6158ca" }} />
                  </span>
                </OverlayTrigger>
              </>
            ) : (
              <span>{row.original.title}</span>
            )}
          </div>
        ),
      },
      {
        Header: "File name",
        accessor: "file_name",
      },
      {
        Header: "Shared",
        Cell: ({ cell }) => (cell.row.original.share ? "Yes" : "No"),
      },
      {
        Header: "Desc",
        accessor: "description",
      },
      {
        Header: "Actions",
        Cell: ({ cell }) => {
          return (
            <div style={{ width: "120px" }}>
              <span
                title={
                  !isPlatformAdmin && cell.row.original.space_id === -1
                    ? `You don't have edit permission`
                    : ""
                }
                className={
                  !isPlatformAdmin && cell.row.original.space_id === -1
                    ? "permission-disabled"
                    : ""
                }
              >
                <Link
                  to={`/action/edit/${cell.row.original.action_id}`}
                  className="text-body"
                >
                  <i className="ti ti-edit ti-sm me-2" />
                </Link>
              </span>
              <AccessWrapper hasAccess="create">
                <Link
                  to="#"
                  className="text-body"
                  onClick={() => handleCopyClick(cell.row.original)}
                >
                  <i className="ti ti-copy ti-sm mx-2"></i>
                </Link>
              </AccessWrapper>
              <AccessWrapper hasAccess="delete">
                <Link
                  to="#"
                  className="text-body delete-record"
                  onClick={() => handleDelete(cell.row.original.action_id)}
                >
                  <i className="ti ti-trash ti-sm mx-2" />
                </Link>
              </AccessWrapper>
            </div>
          );
        },
      },
    ],
    [handleDelete]
  );

  const filteredPosts = useFilteredData(data, searchText, filterByVal);

  const handleAddNew = () => {
    navigate("/action/new");
  };

  const handleAddNewKey = () => {
    setEditKey(null);
    setShowAddNewKey(true);
  };

  return (
    <ContentWrapper>
      <Banner>Actions</Banner>

      <div className="card mt-5">
        <div
          className="card-header flex-column flex-md-row"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="head-label text-center">
            <h5 className="card-title mb-0">
              {activeTab === "action"
                ? "Code Actions Library"
                : "Environment Variables"}
            </h5>
          </div>
          <div className="dt-action-buttons text-end pt-6 pt-md-0">
            <div className="dt-buttons btn-group flex-wrap">
              {" "}
              <div className="btn-group">
                <button
                  className="btn btn-secondary buttons-collection  btn-label-primary me-4 waves-effect waves-light border-none"
                  tabIndex={0}
                  aria-controls="DataTables_Table_0"
                  type="button"
                  aria-haspopup="dialog"
                  aria-expanded="false"
                  onClick={() =>
                    activeTab === "action"
                      ? setActiveTab("env")
                      : setActiveTab("action")
                  }
                >
                  <span>
                    {" "}
                    {activeTab === "action" ? (
                      <i className="ti ti ti-key ti-xs me-sm-1" />
                    ) : (
                      <ActionIcon />
                    )}
                    <span className="d-none d-sm-inline-block">
                      {activeTab === "action"
                        ? "List Env Variables"
                        : "List Actions"}
                    </span>
                  </span>
                </button>
              </div>{" "}
              {activeTab === "action" ? (
                <AccessWrapper hasAccess="create">
                  <AddNewBtn handleAddNew={handleAddNew}>
                    Add New Action
                  </AddNewBtn>
                </AccessWrapper>
              ) : keyFetching ? (
                ""
              ) : (
                <span
                  title={isAllowed ? "" : 'You don"t have create permission'}
                  className={isAllowed ? "" : "permission-disabled"}
                >
                  <AddNewBtn handleAddNew={handleAddNewKey}>
                    Add New Variable
                  </AddNewBtn>
                </span>
              )}
            </div>
          </div>
        </div>
        <hr class="my-0"></hr>

        <div className="row mx-2 my-3">
          <div className="col-md-2 m-auto">
            <div className={styles.postsPerPage}>
              <PostPerPage
                limit={postsPerPage}
                setPostPerPage={setPostPerPage}
              />
            </div>
          </div>
          <div className="col-md-10">
            <div
              className={`dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0 `}
            >
              {/* <button
                className="btn btn-secondary buttons-collection  btn-label-primary me-4 waves-effect waves-light border-none"
                tabIndex={0}
                aria-controls="DataTables_Table_0"
                type="button"
                aria-haspopup="dialog"
                aria-expanded="false"
                onClick={() =>
                  activeTab === "action"
                    ? setActiveTab("env")
                    : setActiveTab("action")
                }
              >
                <span>
                  {" "}
                  {activeTab === "action" ? (
                    <i className="ti ti ti-key ti-xs me-sm-1" />
                  ) : (
                    <ActionIcon />
                  )}
                  <span className="d-none d-sm-inline-block">
                    {activeTab === "action"
                      ? "List Env Variables"
                      : "List Actions"}
                  </span>
                </span>
              </button> */}
              <div className={styles.tableFilter}>
                <SearchFilter
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
              </div>

              {/* {activeTab === "action" ? (
                  <AddNewBtn handleAddNew={handleAddNew}>
                    Add New Action
                  </AddNewBtn>
                ) : keyFetching ? (
                  ""
                ) : (
                  <AddNewBtn handleAddNew={handleAddNewKey}>
                    Add New Variable
                  </AddNewBtn>
                )} */}
            </div>
          </div>
        </div>

        {activeTab === "action" ? (
          isFetching ? (
            <div className={styles.cubeLoader}>
              {" "}
              <CubeSpinner />
            </div>
          ) : error ? (
            <>
              <ErrorAlert>{getStandardErrMsg(error)}</ErrorAlert>
            </>
          ) : (
            <ReactTable
              columns={columns}
              data={filteredPosts}
              pageRows={postsPerPage}
            />
          )
        ) : keyFetching ? (
          <div className={styles.cubeLoader}>
            {" "}
            <CubeSpinner />
          </div>
        ) : keyError ? (
          <>
            <ErrorAlert>
              Some Error occurred. Please contact administrator
            </ErrorAlert>
          </>
        ) : (
          <>
            <ReactTable
              columns={keyColumns}
              data={filteredKeyData}
              pageRows={postsPerPage}
            />
          </>
        )}
      </div>
      {selectedRow && (
        <CopyRowModal
          show={showModal}
          onHide={() => setShowModal(false)}
          onCopy={handleCopy}
          existingNames={filteredPosts.map((post) => post.title)}
          modalTitle="Copy Action"
          fieldName="New Action name"
          prefilledName={`${selectedRow.title}_1`}
          isLoading={addActionInfo?.isLoading || false}
        />
      )}
      {showAddNewKey && (
        <AddNewKey
          setShowAddNewUser={setShowAddNewKey}
          editKey={editKey}
          setEditKey={setEditKey}
          Stashed
          changes
        />
      )}
    </ContentWrapper>
  );
};

export default ActionsScreen;
