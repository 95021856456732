import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { aibotApiStdUrl, credentials, env } from "../constants/constants";
import { current } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { getCookieByDomain } from "../utils/utils";

export const workforceApi = createApi({
  reducerPath: "workforceApi",

  baseQuery: fetchBaseQuery({
    baseUrl: aibotApiStdUrl,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = getCookieByDomain("crave_jwt_access_token");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
    credentials: credentials,
  }),
  keepUnusedDataFor: 86400,
  endpoints: (builder) => ({
    getWorkforces: builder.query({
      query: (currentSpace) => `workforces/${currentSpace}`,
      providesTags: (result) =>
        result && result.length > 0
          ? [
              ...result.map(({ id }) => ({ type: "Workforce", id })),
              { type: "Workforce", id: "WorkforcesList" },
            ]
          : [{ type: "Workforce", id: "WorkforcesList" }],
    }),
    getWorkforcesById: builder.query({
      query: (id) => `workforces_id/${id}`,
    }),
    addWorkforce: builder.mutation({
      query: (body) => ({
        url: `workforces/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "Workforce", id: "WorkforcesList" }],
    }),
    validateWorkforce: builder.mutation({
      query: (body) => ({
        url: `validate-agent-spec/`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: [{ type: "Workforce", id: "WorkforcesList" }],
    }),
    updateWorkforce: builder.mutation({
      query: ({ body, workforce_id, usersData }) => ({
        url: `workforces/${workforce_id}`,
        method: "POST",
        body: body,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(
        { body, workforce_id, usersData },
        { dispatch, queryFulfilled, getCacheEntry }
      ) {
        const patchResult = dispatch(
          workforceApi.util.updateQueryData(
            "getWorkforces",
            body.space_id,
            (draft) => {
              const copy = current(draft);

              const updated = copy.map((item) => {
                const newObj = { ...item };

                if (item.workforce_id === Number(workforce_id)) {
                  newObj["title"] = body.title;
                  console.log(body, "body*****************");
                  // newObj["config"] = body.config;
                  newObj["description"] = body.description;
                  newObj["framework"] = body.framework;
                  newObj["stage"] = body.stage;
                  newObj["share"] = body.share;

                  if (usersData && usersData.length > 0) {
                    newObj["users"] = usersData?.filter((user) =>
                      body.users?.includes(user.user_id)
                    );
                  }

                  const workforceVersions = JSON.parse(
                    JSON.stringify(newObj["workforce_versions"])
                  );

                  workforceVersions[0]["model_id"] = Number(body.model_id);

                  workforceVersions[0]["database_id"] = Number(
                    body.database_id
                  );
                  workforceVersions[0]["stage"] = body.stage;
                  workforceVersions[0]["config"] = body.config;
                  newObj["workforce_versions"] = workforceVersions;
                }
                return newObj;
              });

              Object.assign(draft, updated);
            }
          )
        );
        const patchResult2 = dispatch(
          workforceApi.util.updateQueryData(
            "getWorkforcesById",
            workforce_id,
            (draft) => {
              const copy = current(draft);
              console.log(copy, "cpcp");

              const newObj = { ...copy };
              newObj["title"] = body.title;

              // newObj["config"] = body.config;
              newObj["description"] = body.description;
              newObj["framework"] = body.framework;
              newObj["stage"] = body.stage;
              newObj["share"] = body.share;

              if (usersData && usersData.length > 0) {
                newObj["users"] = usersData?.filter((user) =>
                  body.users?.includes(user.user_id)
                );
              }

              const workforceVersions = JSON.parse(
                JSON.stringify(newObj["workforce_versions"])
              );

              workforceVersions[0]["model_id"] = Number(body.model_id);

              workforceVersions[0]["database_id"] = Number(body.database_id);
              workforceVersions[0]["stage"] = body.stage;
              workforceVersions[0]["config"] = body.config;

              newObj["workforce_versions"] = workforceVersions;

              Object.assign(draft, newObj);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch (error) {
          console.log(error, "error");
          patchResult.undo();
        }
      },
    }),

    deleteWorkforce: builder.mutation({
      query: (body) => ({
        url: `workforces/${body.id}/`,
        method: "DELETE",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled, getCacheEntry }) {
        let patchResult;
        try {
          patchResult = dispatch(
            workforceApi.util.updateQueryData(
              "getWorkforces",
              body.currentSpace,
              (draft) => {
                const index = draft.findIndex((item) => {
                  return item.workforce_id === body.id;
                });
                if (index > -1) {
                  draft.splice(index, 1);
                }
              }
            )
          );
        } catch (error) {
          console.log(error);
          if (patchResult && patchResult.undo) {
            patchResult.undo();
          }
        }
      },
    }),
  }),
});

export const updateGetWorkforceByIdCache = (id, newData) => {
  // workforceApi.endpoints.getWorkforcesById.invalidateQuery(id);
  workforceApi.util.updateQueryData("getWorkforcesById", id, () => newData);
};

export const {
  useGetWorkforcesQuery,
  useDeleteWorkforceMutation,
  useAddWorkforceMutation,
  useValidateWorkforceMutation,
  useGetWorkforcesByIdQuery,
  useUpdateWorkforceMutation,
} = workforceApi;
