import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

// Read the initial state from cookies, fallback to `false` if the cookie is not set or invalid
const initialState = {
  isExpanded: Cookies.get("isExpanded") === "true" ? true : false,
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toggleExpanded(state) {
      state.isExpanded = !state.isExpanded;
      // Update the cookie whenever the state changes
      Cookies.set("isExpanded", state.isExpanded.toString());
    },
    setExpanded(state, action) {
      state.isExpanded = action.payload;
      // Update the cookie whenever the state changes
      Cookies.set("isExpanded", state.isExpanded.toString());
    },
  },
});

export const { toggleExpanded, setExpanded } = sidebarSlice.actions;

// export default sidebarSlice.reducer;
