import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

export const makeid = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export function isValidName(name) {
  name = name.trim();
  if (name) {
    if (/^[a-zA-Z\s]+$/.test(name)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function isValidEmail(email) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email) {
    console.log("Email address is required.");
    return false;
  }
  if (!emailPattern.test(email)) {
    console.log("Invalid email address.");
    return false;
  }
  if (email.length > 320) {
    console.log("Email address is too long.");
    return false;
  }
  return true;
}

export function getInitials(inputString) {
  if (!inputString) return;

  // Split the input string into an array of words
  let words = inputString.split(" ");

  // Initialize an empty string to store the initials
  let initials = "";

  // Iterate through each word in the array
  for (let i = 0; i < words.length; i++) {
    // Get the first character of the word and append it to the initials string
    initials += words[i][0];

    // Break the loop if we already have collected two initials
    if (initials.length >= 2) {
      break;
    }
  }

  // Output the result
  return initials;
}

export const filterByVal = (array, value) => {
  return array.filter(
    (data) =>
      JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
  );
};

export function clearCookies() {
  var cookies = document.cookie.split(";");
  console.log(cookies, "cookies");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];

    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  }
}

export const defaultRegion = (array, value) => {
  const space = JSON.parse(localStorage.getItem("defaultSpace"));
  return space;
};

export function isJSON(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function beautifyJson(json) {
  if (typeof json === "string") {
    try {
      json = JSON.parse(json);
    } catch (e) {
      console.error("Invalid JSON string", e);
      return null;
    }
  }
  return JSON.stringify(json, null, 2);
}

export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const getNestedValue = (obj, path, defaultValue) => {
  return path.reduce((acc, key) => {
    return acc && acc[key] !== undefined ? acc[key] : defaultValue;
  }, obj);
};
export const truncateDescription = (description, maxLength = 40) => {
  if (description.length <= maxLength) {
    return description;
  }
  return description.slice(0, maxLength) + "...";
};
export function isObject(value) {
  return value !== null && typeof value === "object" && !Array.isArray(value);
}

export function renameCookiesWithDomain() {
  const cookies = document.cookie.split(";");
  const domain = document.domain;

  let prefix = "";
  let hostname = "localhost";
  let hostname2 = "localhost";
  if (domain === "localhost") {
    prefix = "localhost_";
  } else if (domain.endsWith("craveai.nonprod.yumds.com")) {
    prefix = "nonprod_";
    hostname = ".nonprod.yumds.com";
    hostname2 = "re-api.nonprod.yumds.com";
  } else if (domain.endsWith("craveai.yumds.com")) {
    prefix = "prod_";
    hostname = ".yumds.com";
    hostname2 = "aibot.yumds.com";
  }

  cookies.forEach((cookie) => {
    const [rawName, ...rest] = cookie.split("=");
    const name = rawName.trim();
    const cookiesList = [
      "crave_email",
      "crave_access_token",
      "crave_jwt_access_token",
    ];
    if (cookiesList.includes(name)) {
      const value = rest.join("=").trim();

      if (!name) return;
      if (prefix && name.startsWith(prefix)) return;
      if (!prefix) return;

      const newName = `${prefix}${name}`;
      document.cookie = `${newName}=${value};path=/;expires=Fri, 31 Dec 9999 23:59:59 GMT;domain=${hostname}`;
      document.cookie = `${newName}=${value};path=/;expires=Fri, 31 Dec 9999 23:59:59 GMT; domain=${hostname2}`;
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${hostname}`;
    }
  });
}

export function getPrefixedCookieName(cookieName) {
  const currentDomain = document.domain;
  let prefix = "";
  if (currentDomain === "localhost") {
    prefix = "localhost_";
  } else if (currentDomain.endsWith("craveai.nonprod.yumds.com")) {
    prefix = "nonprod_";
  } else if (currentDomain.endsWith("craveai.yumds.com")) {
    prefix = "prod_";
  }

  const cookiesList = [
    "crave_email",
    "crave_access_token",
    "crave_jwt_access_token",
  ];

  return prefix && cookiesList.includes(cookieName)
    ? `${prefix}${cookieName}`
    : cookieName;
}

export function getCookieByDomain(cookieName) {
  const cookieValue = Cookies.get(getPrefixedCookieName(cookieName));
  if (cookieValue) {
    try {
      if (
        cookieValue.startsWith("{") ||
        cookieValue.startsWith("[") ||
        cookieValue.startsWith('"')
      ) {
        return JSON.parse(
          cookieValue.replace(/\\054/g, ",").replace(/^"|"$/g, "")
        );
      }
      return cookieValue;
    } catch (error) {
      return cookieValue;
    }
  }

  return null;
}

export function getStandardErrMsg(errorObj) {
  const errorMessages = {
    FETCH_ERROR: "500 Internal Server Error.",
    SERVER_ERROR: "Server unavailable. Try later.",
    TIMEOUT_ERROR: "Request timed out. Retry soon.",
    AUTH_ERROR: "Login failed. Please re-login.",
    VALIDATION_ERROR: "Invalid input. Check your data.",
    NOT_FOUND_ERROR: "Resource not found.",
    FORBIDDEN_ERROR: "Access denied.",
    BAD_REQUEST_ERROR: "Bad request. Please try again.",
    CONFLICT_ERROR: "Conflict detected. Try later.",
    INTERNAL_ERROR: "Something went wrong. Try later.",
    UNKNOWN_ERROR: "Unexpected error. Contact support.",
  };

  const defaultErrorMsg = `Some error occurred. Please contact the admin.`;

  if (errorObj) {
    const { status, error } = errorObj;

    if (errorMessages[status]) {
      return `Error : ${errorMessages[status]}`;
    }

    return `${error || defaultErrorMsg}`;
  }

  return defaultErrorMsg;
}

// export function getUserAccess(cookieName) {
//   const currentDomain = window.location.hostname;
//   const cookieValue = Cookies.get(cookieName);

//   if (cookieValue) {
//     if (
//       document.domain === currentDomain ||
//       currentDomain.endsWith(document.domain)
//     ) {
//       if (
//         cookieValue.startsWith("{") ||
//         cookieValue.startsWith("[") ||
//         cookieValue.startsWith('"')
//       ) {
//         try {
//           return JSON.parse(
//             cookieValue.replace(/\\054/g, ",").replace(/^"|"$/g, "")
//           );
//         } catch (error) {
//           console.error("Error parsing JSON cookie:", error);
//           return cookieValue;
//         }
//       }
//       return cookieValue;
//     }
//   }

//   return null;
// }

export const userAccessFunc = (cookieName) => {
  const currentDomain = window.location.hostname;
  const cookieValue = Cookies.get(cookieName);

  if (cookieValue) {
    if (
      document.domain === currentDomain ||
      currentDomain.endsWith(document.domain)
    ) {
      let formattedString = cookieValue
        .replace(/\\054/g, ",") // Replace \054 with ,
        .replace(/'/g, '"');
      console.log("🚀 ~ userAccessFunc ~ formattedString:", formattedString);

      try {
        let jsonObject = JSON.parse(formattedString);

        return jsonObject;
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }
  return null;
};

export const getCleanAccessTokenValue = (dataStr) => {
  const cleanedData = dataStr.replace(/\\054/g, ",");
  const jsonDataString = cleanedData.replace(/'/g, '"');
  return JSON.parse(jsonDataString);
};

export const isPlatformAdminUser = () => {
  const accessToken = Cookies.get(
    getPrefixedCookieName("crave_jwt_access_token")
  );
  if (accessToken) {
    const decodedToken = jwtDecode(accessToken);
    const spaceDetails = decodedToken?.access?.access?.space_details || [];

    const isPlatformAdmin = spaceDetails.some((space) => {
      const privileges = JSON.parse(space.PRIVILEGES || "{}");
      return privileges.platform_admin;
    });

    return isPlatformAdmin;
  }
  return false;
};
