import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Banner from "../../Components/Banner/Banner";
import ReactTable from "../../Components/ReactTable/ReactTable";
import { CubeSpinner } from "../../Components/Spinners/Spinners";
import { ErrorAlert } from "../../Components/Alerts/Alerts";
import styles from "./database.module.css";
import AccessWrapper from "../../Components/AccessWrapper/AccessWrapper";

import {
  filterByVal,
  getStandardErrMsg,
  isPlatformAdminUser,
} from "../../utils/utils";

import {
  useDeleteDatabaseMutation,
  useGetDatabasesQuery,
} from "../../services/databaseService";

import ContentWrapper from "../../Components/ContentWrapper/ContentWrapper";
import useConfirmDelete from "../../utils/useConfirmDelete";
import FillterWrapper from "../../Components/TableFilterWrapper/TableFillterWrapper";
import { useSelector } from "react-redux";
import useFilteredData from "../../utils/useFilteredData";
import { MdInfo } from "react-icons/md";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const DatabaseScreen = () => {
  const navigate = useNavigate();
  const currentSpace = useSelector((state) => state.defaultSpace.data);
  const isPlatformAdmin = isPlatformAdminUser(currentSpace);
  const [postsPerPage, setPostPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");

  const { data, error, isFetching } = useGetDatabasesQuery(currentSpace);
  const [deleteDatabase] = useDeleteDatabaseMutation();
  const confirmDelete = useConfirmDelete(deleteDatabase);

  const handleDelete = useCallback(
    (id) => {
      confirmDelete(id);
    },
    [confirmDelete]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "database_id",
      },
      {
        Header: "Database",
        accessor: "name",
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {row.original.space_id === -1 ? (
              <>
                <span style={{ color: "#6158ca" }}>{row.original.name}</span>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top">Platform Resource</Tooltip>
                  }
                >
                  <span style={{ marginLeft: "10px", cursor: "pointer" }}>
                    <MdInfo style={{ color: "#6158ca" }} />
                  </span>
                </OverlayTrigger>
              </>
            ) : (
              <span>{row.original.name}</span>
            )}
          </div>
        ),
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Actions",
        Cell: ({ cell }) => (
          <>
            <span
              title={
                !isPlatformAdmin && cell.row.original.space_id === -1
                  ? `You don't have edit permission`
                  : ""
              }
              className={
                !isPlatformAdmin && cell.row.original.space_id === -1
                  ? "permission-disabled"
                  : ""
              }
            >
              <Link
                to={`/database/edit/${cell.row.original.database_id}`}
                className="text-body"
              >
                <i className="ti ti-edit ti-sm me-2" />
              </Link>
            </span>
            <AccessWrapper hasAccess="delete">
              <Link
                to="#"
                className="text-body delete-record"
                onClick={() => handleDelete(cell.row.original.database_id)}
              >
                <i className="ti ti-trash ti-sm mx-2" />
              </Link>
            </AccessWrapper>
          </>
        ),
      },
    ],
    [handleDelete]
  );

  const filteredPosts = useFilteredData(data, searchText, filterByVal);

  const handleAddNew = () => {
    navigate("/database/new");
  };

  return (
    <ContentWrapper>
      <Banner>Database</Banner>
      <div className="card mt-5">
        <FillterWrapper
          postsPerPage={postsPerPage}
          setPostPerPage={setPostPerPage}
          searchText={searchText}
          setSearchText={setSearchText}
          handleAddNew={handleAddNew}
          btnText="Database"
        />
        {isFetching ? (
          <div className={styles.cubeLoader}>
            {" "}
            <CubeSpinner />
          </div>
        ) : error ? (
          <>
            <ErrorAlert>{getStandardErrMsg(error)}</ErrorAlert>
          </>
        ) : (
          <ReactTable
            columns={columns}
            data={filteredPosts}
            pageRows={postsPerPage}
          />
        )}
      </div>
    </ContentWrapper>
  );
};

export default DatabaseScreen;
