import React, { useRef, useCallback, useEffect, useState } from "react";
import { Editor } from "@monaco-editor/react";
import { FiMaximize, FiMinimize } from "react-icons/fi";
import { debounce } from "lodash";
import styles from "./fullscreeneditor.module.css";
import { useDispatch } from "react-redux";
import { setExpanded } from "../../services/sidebarSlice";

const FullscreenEditor = ({
  height = "50vh",
  defaultLanguage = "json",
  defaultValue,
  theme = "vs-dark",
  options,
  onMount,
  key,
  onChange,
  ...props
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const normalEditorRef = useRef(null);
  const fullscreenEditorRef = useRef(null);
  const dispatch = useDispatch();

  const handleEditorDidMount = (editor) => {
    if (onMount) onMount(editor);
    if (isFullscreen) {
      dispatch(setExpanded(false));

      fullscreenEditorRef.current = editor;
    } else {
      normalEditorRef.current = editor;
      dispatch(setExpanded(true));
    }
    editor.layout();
  };

  const debouncedOnChange = useCallback(
    debounce((value) => {
      if (onChange) onChange(value);
    }, 500),
    [onChange]
  );

  const toggleFullscreen = () => {
    setIsFullscreen((prev) => !prev);
  };

  useEffect(() => {
    const layoutEditor = () => {
      const editor = isFullscreen
        ? fullscreenEditorRef.current
        : normalEditorRef.current;
      if (editor) {
        editor.layout();
      }
    };

    layoutEditor();
  }, [isFullscreen]);

  return (
    <div>
      {!isFullscreen && (
        <div className={styles.normalEditor}>
          <div className="editor-header d-flex justify-content-end">
            <button
              className="btn btn-secondary btn-md"
              onClick={toggleFullscreen}
              title="Fullscreen"
            >
              <FiMaximize />
            </button>
          </div>
          <Editor
            height={height}
            defaultLanguage={defaultLanguage}
            defaultValue={defaultValue}
            theme={theme}
            options={options}
            onMount={handleEditorDidMount}
            key={key}
            onChange={debouncedOnChange}
            {...props}
          />
        </div>
      )}

      {isFullscreen && (
        <div className={styles.fullscreenEditor}>
          <div className="editor-header d-flex justify-content-end">
            <button
              className="btn btn-secondary btn-md mr-5"
              onClick={toggleFullscreen}
              title="Exit Fullscreen"
            >
              <FiMinimize />
            </button>
          </div>
          <Editor
            height="100vh"
            defaultLanguage={defaultLanguage}
            defaultValue={defaultValue}
            theme={theme}
            options={options}
            onMount={handleEditorDidMount}
            key={key}
            onChange={debouncedOnChange}
            {...props}
          />
        </div>
      )}
    </div>
  );
};

export default FullscreenEditor;
