import React, { useRef, useState } from "react";
import Banner from "../../Components/Banner/Banner";
import { Row } from "react-bootstrap";

import InputField, { Textarea } from "../../Components/Input/Input";
import Editor from "@monaco-editor/react";

import isEmpty from "validator/es/lib/isEmpty";
import {
  useAddModelMutation,
  useGetModelsByIdQuery,
  useUpdateModelMutation,
} from "../../services/modelsService";
import { useEffect } from "react";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { CubeSpinner } from "../../Components/Spinners/Spinners";
import styles from "./models.module.css";
import { ErrorAlert } from "../../Components/Alerts/Alerts";
import useOperationFeedback from "../../utils/useOperationFeedback";
import {
  NewStepHeader,
  StepsHeader,
} from "../../Components/StepsHeader/StepsHeader";
import CurrentStepHeading from "../../Components/CurrentStepHeading/CurrentStepHeading";
import {
  LoadingButton,
  PrimaryButton,
  SecondaryButton,
} from "../../Components/Buttons/Buttons";
import FieldWrapper from "../../Components/FieldWrapper/FieldWrapper";
import ContentWrapper from "../../Components/ContentWrapper/ContentWrapper";
import BackButton from "../../Components/BackButton/BackButton";
import { getStandardErrMsg } from "../../utils/utils";
const AddNewModel = () => {
  const currentSpace = useSelector((state) => state.defaultSpace.data);

  const { model_id } = useParams();

  const skip = model_id ? false : true;

  const { data, error, isFetching } = useGetModelsByIdQuery(model_id, { skip });

  const editorRef = useRef(null);
  const [activeStep, setActiveStep] = useState(1);

  const [fieldError, setFieldError] = useState({
    name: "",
    type: "",
    api_key: "",
    base_url: "",
    api_type: "",
    api_version: "",
  });

  const [model, setModel] = useState({
    name: "",
    desc: "",
    api_key: "",
    base_url: "",
    api_type: "",
    api_version: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (model_id && data) {
      setModel({ name: data.model, desc: data.description, ...data.config });
    }
  }, [data, model_id]);

  const [addModel, addModelInfo] = useAddModelMutation();
  const [updateModel, updateModelInfo] = useUpdateModelMutation();

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
    setTimeout(function () {
      editor.getAction("editor.action.formatDocument").run();
    }, 300);
  }

  useOperationFeedback(addModelInfo.isSuccess, addModelInfo.isError, "/models");
  useOperationFeedback(
    updateModelInfo.isSuccess,
    updateModelInfo.isError,
    "/models"
  );

  const handlePrev = () => {
    setActiveStep(1);
  };

  const handleValidation = (advance = false) => {
    let valid = true;
    let errors = {
      name: "",
      desc: "",
      api_key: "",
      base_url: "",
      api_type: "",
      api_version: "",
    };

    if (isEmpty(model.name)) {
      errors.name = "Please enter model name";
      valid = false;
    }
    if (isEmpty(model.desc)) {
      errors.desc = "Please enter model description";
      valid = false;
    }

    // if (advance) {
    //   if (isEmpty(model.api_key)) {
    //     errors.api_key = "Please enter api key";
    //     valid = false;
    //   }
    //   if (isEmpty(model.base_url)) {
    //     errors.base_url = "Please enter base url";
    //     valid = false;
    //   }
    //   if (isEmpty(model.api_type)) {
    //     errors.api_type = "Please enter api type";
    //     valid = false;
    //   }
    //   if (isEmpty(model.api_version)) {
    //     errors.api_version = "Please enter api version";
    //     valid = false;
    //   }
    // }
    if (!valid) {
      setFieldError(errors);
    }
    return valid;
  };

  const handleNext = (basic = true) => {
    if (handleValidation()) {
      setActiveStep(activeStep + 1);
    } else {
    }
  };

  function showValue() {
    if (handleValidation(true)) {
      let jsonObject;
      if (activeStep === 1) {
        jsonObject = Object.entries(model).reduce((acc, [key, value]) => {
          if (key !== "name" && key !== "desc") {
            acc[key] = value;
          }
          return acc;
        }, {});
      }

      console.log("🚀 ~ jsonObject ~ jsonObject:", jsonObject);

      const body = {
        space_id: model_id ? data.space_id : currentSpace,
        model: model.name,
        description: model.desc,
        config:
          activeStep === 1
            ? jsonObject
            : JSON.parse(editorRef.current.getValue()),
      };
      console.log("🚀 ~ showValue ~ body:", body);

      model_id
        ? updateModel({ body: body, model_id: model_id })
        : addModel(body);
    }
  }

  return (
    <ContentWrapper>
      <Banner>{model_id ? "Edit" : "Add"} Model</Banner>

      <Row className="mt-5">
        <BackButton />

        {isFetching ? (
          <div className={styles.cubeLoader}>
            {" "}
            <CubeSpinner />
          </div>
        ) : error ? (
          <>
            <ErrorAlert>{getStandardErrMsg(error)}</ErrorAlert>
          </>
        ) : (
          <div className="bs-stepper wizard-vertical vertical mt-2">
            <StepsHeader>
              <NewStepHeader
                activeStep={activeStep}
                stepNumber={1}
                stepTitle={"New Model"}
                stepSubtitle={"Enter model details"}
                handleClick={handlePrev}
              />
              <div className="line" />

              <NewStepHeader
                activeStep={activeStep}
                stepNumber={2}
                stepTitle={"Configurations"}
                stepSubtitle={"Enter model configuration"}
                handleClick={handleNext}
              />
            </StepsHeader>

            <div className="bs-stepper-content">
              {activeStep === 1 && (
                <Step1
                  model={model}
                  setModel={setModel}
                  data={data}
                  error={fieldError}
                  handleNext={showValue}
                  addModelInfo={addModelInfo}
                  updateModelInfo={updateModelInfo}
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                />
              )}
              {activeStep === 2 && (
                <Step2
                  setActiveStep={setActiveStep}
                  showValue={showValue}
                  handleEditorDidMount={handleEditorDidMount}
                  addModelInfo={addModelInfo}
                  updateModelInfo={updateModelInfo}
                  data={data}
                  handlePrev={handlePrev}
                />
              )}
            </div>
          </div>
        )}
      </Row>
    </ContentWrapper>
  );
};

const Step1 = ({
  model,
  setModel,
  error,
  handleNext,
  addModelInfo,
  updateModelInfo,
  showPassword,
  setShowPassword,
}) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    setModel((prevSpace) => ({
      ...prevSpace,
      [name]: value,
    }));
  };

  return (
    <>
      <CurrentStepHeading title="Model" titleSummary="Enter model details" />

      <FieldWrapper errorMsg={error.name}>
        <InputField
          type="text"
          value={model.name}
          placeholder="Model Name"
          label="Model   Name"
          name="name"
          onChange={handleChange}
        />
      </FieldWrapper>
      <FieldWrapper errorMsg={error.desc}>
        <Textarea
          value={model.desc}
          placeholder="Desc..."
          label="Description"
          name="desc"
          onChange={handleChange}
        />
      </FieldWrapper>

      <FieldWrapper errorMsg={error.api_key}>
        <InputField
          type={showPassword ? "text" : "password"}
          icon={showPassword ? "ti ti-eye-off" : "ti ti-eye"}
          onIconClick={() => setShowPassword(!showPassword)}
          value={model.api_key || model.apiKey}
          placeholder="enter your key"
          label="API Key"
          name="api_key"
          onChange={handleChange}
        />
      </FieldWrapper>
      <FieldWrapper errorMsg={error.base_url}>
        <InputField
          type="text"
          value={model.base_url || model.baseUrl}
          placeholder="enter base url"
          label="Base URL"
          name="base_url"
          onChange={handleChange}
        />
      </FieldWrapper>
      <FieldWrapper errorMsg={error.api_type}>
        <InputField
          type="text"
          value={model.api_type || model.apiType}
          placeholder="enter api type"
          label="API Type"
          name="api_type"
          onChange={handleChange}
        />
      </FieldWrapper>
      <FieldWrapper errorMsg={error.api_version}>
        <InputField
          type="text"
          value={model.api_version || model.apiVersion}
          placeholder="enter your key"
          label="API Version"
          name="api_version"
          onChange={handleChange}
        />
      </FieldWrapper>

      {addModelInfo.isLoading || updateModelInfo.isLoading ? (
        <LoadingButton />
      ) : (
        <PrimaryButton handleClick={handleNext} variant="submit">
          Submit{" "}
        </PrimaryButton>
      )}
    </>
  );
};

const Step2 = ({
  showValue,
  handleEditorDidMount,
  addModelInfo,
  data,
  handlePrev,
  updateModelInfo,
}) => {
  return (
    <>
      <CurrentStepHeading
        title="Configuration"
        titleSummary="Enter configuration in JSON format."
      />

      <Editor
        height="50vh"
        defaultLanguage="json"
        defaultValue={
          data ? JSON.stringify(data.config) : `{ "example": "example" }`
        }
        theme="vs-dark"
        options={{
          minimap: {
            enabled: false,
          },

          glyphMargin: false,
          folding: false,
          lineDecorationsWidth: 0,
          lineNumbersMinChars: 0,
          wordWrapColumn: 80,
          wordWrapMinified: true,
          autoIndent: "advanced",
          disableLayerHinting: false,
          automaticLayout: true,
          formatOnType: true, // Format the content when typing
          formatOnPaste: true, // Format the content when pasting
        }}
        onMount={handleEditorDidMount}
      />
      <div className="col-12 d-flex justify-content-between mt-3">
        <SecondaryButton handleClick={handlePrev}>
          {" "}
          <i className="ti ti-arrow-left me-sm-1 me-0" /> Previous
        </SecondaryButton>
        {addModelInfo.isLoading || updateModelInfo.isLoading ? (
          <LoadingButton />
        ) : (
          <PrimaryButton handleClick={showValue} variant="submit">
            Submit{" "}
          </PrimaryButton>
        )}
      </div>
    </>
  );
};

export default AddNewModel;
